// header
.header {
  align-items: center;
  background-color: $tertiary-900;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height: $header-height;
  padding-left: 1rem;
  padding-right: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 200;

  .body-is-loading & {
    pointer-events: none;
  }

  .logo {
    height: 2rem;
  }

  .header--title {
    color: $white;
    margin-bottom: 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .nav-item {
    + .nav-item {
      margin-left: 0.5rem;
    }
  }

  .btn-link.btn--element-start,
  .btn-link.btn--element-end {
    --fa-fw-width: 2rem;
    height: 2.5rem;
    padding: 0.25rem;

    .svg-inline--fa.fa-fw {
      color: $white;
      font-size: var(--fa-fw-width);
      top: unset;
    }
  }

  // nav spacing to make sure the header title is centered
  .nav-spacing--start {
    max-width: 92px;
    width: 100%;
  }

  .nav-spacing--end {
    display: flex;
    justify-content: flex-end;
    min-width: 100px;
  }
}
