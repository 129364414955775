:root {
  --hf-btn--content_box-shadow: #{$box-shadow-xs};
  --hf-btn--content_margin-between: 1rem;
}

// Button content
.btn--content {
  align-items: normal;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: var(--hf-btn--content_box-shadow);
  padding: 1rem;
  text-align: start;
  width: 100%;

  &:hover {
    --hf-btn-hover-bg: #{$white};
  }

  .btn--content_block {
    display: flex;
    justify-content: space-between;

    + .btn--content_block {
      border-top: 1px solid $tertiary-200;
      margin-top: 1rem;
      padding-top: 1rem;
    }
  }

  .btn--content__content {
    p {
      font-size: $small-font-size-rem;
    }

    h2,
    p {
      color: $tertiary-900;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  + .btn--content {
    margin-top: var(--hf-btn--content_margin-between);
  }
}

.btn--content__accordion-items {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  box-shadow: var(--hf-btn--content_box-shadow);
  clip-path: inset(0px -10px -10px -10px);

  + .btn--content {
    margin-top: var(--hf-btn--content_margin-between);
  }
}

.btn--content__accordion-item {
  border-color: $tertiary-200;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 0;
  box-shadow: none;
  margin: 0;

  h3 {
    color: $tertiary-900;
  }

  &:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  + .btn--content__accordion-item {
    margin-top: 0;
  }
}

.btn--content__message {
  color: $gray-600;
  display: flex;
  font-size: $extra-small-font-size-rem;
  font-weight: $font-weight-normal;
  margin-left: -0.25rem;

  .btn--content__message-info {
    margin-top: 0.5rem;
  }

  .btn--content__message-info + .btn--content__message-info {
    margin-left: 0.5rem;
  }
}

// Used in btn--content and on activity
.fa-stack {
  color: $black;
  margin-right: 0.125rem;

  .fa-circle-exclamation {
    color: $warning;
    right: -1em;
    top: -1em;
  }
}

.btn--content__end {
  align-items: center;
  display: flex;
  margin-left: 1rem;

  .svg-inline--fa {
    @extend .fa--1rem;
    color: $primary;
    font-size: 1.2rem;
  }
}

.btn--rounded--active {
  background-color: $warning;
  border: 2px solid $body-bg;
  border-radius: 50%;
  height: 12px;
  position: absolute;
  right: -0.1rem;
  top: 0.375rem;
  width: 12px;
}

// Filter reset button
.btn--reset-filter {
  color: $white;
  margin-right: -50px;
  padding: 0;

  svg {
    color: $white;
  }
}
