// Content
.block-content--border {
  border-bottom: 1px solid $tertiary-200;
}

.block-content--px {
  padding-left: 1rem;
  padding-right: 1rem;
}

.block-content--py {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

// Search bar
.search-bar {
  background-color: $tertiary-900;
  margin-left: calc(var(--hf-gutter-x) * -0.5);
  margin-right: calc(var(--hf-gutter-x) * -0.5);
}

// Content had a fixed footer
.content--with-actions {
  padding-bottom: 5.5rem;
}

.content--with-nfc {
  padding-bottom: 8.5rem;
}

// Fixed height
.h--100-px {
  height: 100px;
}

// Illustration
.illustration {
  max-width: 200px;
  width: 100%;
}

// Page title
.h1--page-title {
  @extend .text-tertiary;
  font-size: 1rem;
  font-weight: 600;
}

// NFC
.body-is-loading .nfc > div {
  opacity: 0.85;
  pointer-events: none;
}
