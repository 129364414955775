.modal {
  .modal-content {
    box-shadow: $box-shadow-lg;

    .modal-header {
      align-items: unset;

      .modal-title + .modal-title {
        margin-top: 0.5rem;
      }

      .modal-header__info {
        align-items: start;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .modal__steps {
        font-size: 1.5rem;
        font-weight: $font-weight-bold;
        line-height: normal;
      }

      .btn-close {
        background-size: 1.5rem;
        margin-left: 1rem;
        margin-top: -0.375rem;
      }
    }

    .modal-body {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

// Modal body height
.modal-body__height-xs {
  height: $modal-body-height-xs;
}

.modal-body__height-sm {
  height: $modal-body-height-sm;
}

.modal-body__height-md {
  height: $modal-body-height-md;
}

.modal-body__height-lg {
  height: $modal-body-height-lg;
}

.modal-body__height-xl {
  height: $modal-body-height-xl;
}

// Modal content max height
.modal-content {
  max-height: 80vh;
}

// modal column margin bottom
.modal__col-mb {
  margin-bottom: 2rem;
}

// Modal message
.modal__message {
  align-items: center;
  display: flex;
  flex-direction: column;

  img {
    height: 8rem;
    margin-bottom: 1rem;
  }

  h2,
  p {
    margin-bottom: 0;
    text-align: center;
  }

  .modal__message__block + .modal__message__block {
    margin-top: 1rem;
  }
}
