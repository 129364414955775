.table {
  td,
  th {
    font-size: $medium-font-size;
  }

  th,
  h3 {
    color: $tertiary-900;
  }

  th {
    font-weight: $font-weight-bolder;
  }

  h2 {
    color: $primary-800;
  }

  &.table--has-checkbox {
    > thead,
    > tbody {
      > tr {
        > td,
        > th {
          &:first-child {
            min-width: 40px !important;
          }
        }
      }
    }
  }

  &.table--has-expander {
    > thead,
    > tbody {
      > tr {
        > td,
        > th {
          &:last-child {
            padding-right: 0.25rem;
            padding-left: 0.25rem;
          }
        }
      }
    }
  }
}

// Bordered table
.table.table--bordered {
  > thead,
  > tbody {
    > tr {
      > td,
      > th {
        background-color: transparent;
        border: 0;
      }
    }
  }

  > thead > tr {
    > th[title*="SortBy"] {
      cursor: pointer;

      svg {
        margin-left: 0.25rem;
      }
    }
  }

  > tbody {
    position: relative;

    &::before {
      background-color: $table-bg;
      border: 1px solid $table-border-color;
      border-radius: $table-cell-padding-x;
      content: "";
      inset: 0;
      position: absolute;
      z-index: -1;
    }

    > tr {
      > td {
        border-bottom: 1px solid $table-border-color;
      }

      td,
      th {
        &:first-child {
          padding-left: $table-cell-padding-x * 2;
        }
      }

      &.tr-content + tr:not(.tr-content) > td {
        border-top: 1px solid $table-border-color;
      }

      &:last-child > td {
        border-bottom-width: 0;
      }
    }

    > tr:first-child {
      > td:first-child {
        border-top-left-radius: $table-cell-padding-x;
      }

      > td:last-child {
        border-top-right-radius: $table-cell-padding-x;
      }
    }

    > tr:last-child {
      > td:first-child {
        border-bottom-left-radius: $table-cell-padding-x;
      }

      > td:last-child {
        border-bottom-right-radius: $table-cell-padding-x;
      }
    }
  }

  .table.table--bordered--radius-unset > tbody {
    &::before {
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border-top-width: 0;
    }
  }

  // Table borderless with table--bordered no padding first and last td /th
  .table-borderless {
    > thead,
    > tbody {
      > tr {
        > td,
        > th {
          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}

// Table borderless
.table.table-borderless > :not(caption) > * > * {
  padding-bottom: $table-borderless-cell-padding-y;
  padding-top: $table-borderless-cell-padding-y;
}

// Table tr content
.table > tbody > .tr-content > td {
  border-bottom: 0 !important;
  padding-bottom: 0;
  padding-top: 0;

  h3 {
    margin-bottom: 1rem;
  }
}

// Table content spacers (padding top and bottom)
.table > tbody > tr > td {
  &.td-content-pt-3-5 {
    padding-top: 1.25rem;
  }

  &.td-content-pb-3 {
    padding-bottom: 1rem;
  }

  &.td-content-pb-3-5 {
    padding-bottom: 1.25rem;
  }
}

// Table bordered content
.table.table--bordered > tbody > tr > td.td--content {
  padding: $table-cell-padding-x * 4 $table-cell-padding-x * 3;
}

// Table tr spacer
.table > tr.tr-spacer > td {
  height: 0.5rem;
}

// Table connectors
.table > tbody > tr {
  > td.td-connector {
    padding: 0 0 0 2rem;
    position: relative;

    &::before {
      background-color: $green;
      content: "";
      height: calc(100% + 1px);
      position: absolute;
      top: 0;
      width: 2px;
    }

    &::after {
      background-color: $green;
      content: "";
      height: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }

    + td {
      padding-left: 2rem;
    }
  }

  &:last-child > td.td-connector {
    &::before {
      height: 50%;
    }
  }
}

// Tables images
.table .table__img-thumbnail {
  max-height: 1.5rem;
  max-width: 1.5rem;
}

// Fix: Checkbox within React Table
.table--bordered {
  .form-check-input[type="checkbox"][readonly] {
    cursor: pointer;
  }
}
