.logbook__sticky {
  margin-left: -$modal-header-padding-x;
  margin-right: -$modal-header-padding-x;
  margin-top: calc(-#{$modal-header-padding-y} - #{$modal-header-border-width});
  padding: $modal-header-padding-y $modal-header-padding-x;
  position: sticky;
  top: calc(-#{$modal-header-padding-y} - #{$modal-header-border-width});

  &::before {
    background-color: $tertiary-900;
    content: "";
    height: calc(#{$modal-header-padding-y * 2} + 2.5rem); // 2.5rem is height of the input
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
}

.modal--logbook {
  .modal-footer {
    padding: 0;
  }
}

.select--react-select__value-container {
  @extend .form-control--icon--end, .form-control--icon--magnifying-glass;
}

.unread-messages-dot {
  background-color: $warning;
  border-radius: 50%;
  height: 6px;
  position: absolute;
  right: 10px;
  top: 7px;
  width: 6px;
}
