.accordion {
  .accordion-item {
    border: var(--hf-accordion-border-width) solid var(--hf-accordion-border-color);
    border-radius: var(--hf-accordion-border-radius);
    margin-bottom: 0.5rem;
  }

  .accordion-button {
    box-shadow: $accordion-button-focus-box-shadow;
    font-size: $medium-font-size;
    font-weight: $font-weight-bolder;

    &.accordion-button--has-icon {
      display: flex;
      justify-content: space-between;

      &::after {
        margin-left: unset;
        position: absolute;
        right: 2.5rem;
      }
    }
  }

  .accordion-body {
    border-top: var(--hf-accordion-border-width) solid var(--hf-accordion-border-color);
    font-size: $medium-font-size;
  }
}

.btn.accordion--btn-action {
  padding: 0;

  > svg {
    font-size: 1.25em;
  }
}
