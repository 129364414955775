@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url("/assets/fonts/gilroy-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("/assets/fonts/gilroy-medium.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url("/assets/fonts/gilroy-semibold.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url("/assets/fonts/gilroy-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  src: url("/assets/fonts/gilroy-extrabold.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  src: url("/assets/fonts/gilroy-black.woff2") format("woff2");
}
