.logbook {
  --hf-logbook-messages-bg-color: #{$tertiary-100};
}

// Content
.logbook__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  background-color: var(--hf-logbook-messages-bg-color);
}

.logbook__messages-container {
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;

  @include media-breakpoint-up(lg) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
  }
}

// Logbook message
.logbook-message {
  margin-bottom: 1rem;
  max-width: 90%;
  width: fit-content;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
    max-width: 60%;
  }
}

.logbook-message__content {
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 1rem;

  p {
    margin-bottom: 0;
    word-break: break-all;
  }
}

.logbook-message__timestamp {
  color: $tertiary;
  font-size: $extra-small-font-size;
}

.logbook-message--user-sender {
  margin-right: auto;

  .logbook-message__content {
    background-color: $white;
    border-bottom-left-radius: 0;

    h3 {
      color: $green;
    }

    p {
      color: $body-color;
    }
  }
}

.logbook-message--user-current {
  margin-left: auto;

  .logbook-message__content {
    background-color: rgba($tertiary-900, 0.1);
    border-bottom-right-radius: 0;
  }

  .logbook-message__timestamp {
    text-align: right;
  }
}

// Message divider
.logbook-message__messages-divider {
  align-items: center;
  border-top: 1px solid $black;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  span {
    background-color: var(--hf-logbook-messages-bg-color);
    font-size: $small-font-size-rem;
    padding: 0 1rem;
    transform: translateY(-50%);
  }
}

// add message
.logbook__add-message-container {
  align-items: end;
  background-color: $gray-100;
  display: flex;
  padding: 1.5rem;
  width: 100%;

  .logbook__add-message-input-container {
    flex-grow: 1;
    margin-right: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: 2.5rem;
    }
  }

  .form-control {
    height: 2.5rem; // default input size
    resize: none;
    scrollbar-width: none;
  }

  .btn svg {
    --fa-rotate-angle: -45deg;
  }
}
