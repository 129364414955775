.modal {
  &.modal__fullscreen {
    .modal-content .modal-header {
      background-color: $tertiary-900;
      color: $white;
      flex-direction: row-reverse;
      padding: 0.875rem 3.5rem 0.875rem 1rem; // padding-right: same width (including margin) at right side as btn-close size, so the title is centered

      .modal-header__info {
        justify-content: center;

        .modal-title {
          @extend .h4;
        }
      }

      .btn-close {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM180.7 180.7c-6.2 6.2-6.2 16.4 0 22.6L233.4 256l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L256 278.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z"/></svg>');
        background-size: 2rem;
        height: 2rem;
        margin: -4px;
        padding: 0.25rem;
        width: 2rem;
      }

      .modal-header__nav-spacing {
        max-width: 16px;
        width: 100%;
      }
    }

    .modal-body {
      background-color: $tertiary-100;
      padding: 1rem;
    }

    .modal-content .modal-header .modal-header__info {
      align-items: center;
      display: flex;

      .modal-title {
        font-weight: $font-weight-medium;
        margin-bottom: 0;
      }
    }
  }
}
